@import "~@mdi/font/scss/materialdesignicons";

@import "muuri";
@import "dropzone";

@import 'ckeditor5/ckeditor5.css';

a.nav-link {
    color: #1180ae;
}

.ck-editor__editable {
    max-height: calc(100vh - 20rem);
    min-height: calc(100vh - 20rem);
}

@import "~flatpickr/dist/flatpickr.css";
//dropzone
@import "~dropzone/dist/dropzone.css";
$primary-font: "Inter", sans-serif;
body {
    font-family: $primary-font !important;
}

.dropzone {
    border: 0;
    outline: 2px dashed lightblue;
    /* the dash box */
    outline-offset: -10px;
    background: white;
    color: dimgray;
    padding: 10px 10px;
    min-height: 150px;
    /* minimum height */
    position: relative;
    cursor: pointer;
}

.input-file {
    opacity: 0;
    /* invisible but it's there! */
    width: 100%;
    height: 150px;
    position: absolute;
    cursor: pointer;
}

.dropzone:hover {
    outline: 2px dashed white;
    /* inverted */
    background: lightblue;
    /* when mouse over to the drop zone, change color */
}

.dropzone p {
    font-size: 1.2em;
    text-align: center;
    padding: 50px 0;
}
.site-title {
    color: #1180ae;
    padding-left: 1rem;
    font-weight: 500;
}

#default-search {
    border-color: #e5e7eb;
    min-width: 400px;
    border-radius: 16px;

    @media (max-width: 767px) {
        min-width: auto;
    }
}
.site-sidebar {
    position: absolute;
    background: #fff;
    height: 100vh;
    top: 65px;
    left: 0px;
    padding: 20px;
    z-index: 9;
    min-width: 16rem;
}

.flex-grid-static {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .item-static {
        position: static;
    }
}
.main-container-static {
    margin-left: 16rem;
    padding-top: 100px !important;
    min-height: 100vh;

    @media (max-width: 767px) {
        margin-left: 0;
        padding-top: 90px !important;
        padding-bottom: 50px !important;
        padding-left: 15px;
        padding-right: 15px;
    }
}
#sidebar-multi-level-sidebar {
    padding-top: 100px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 40px;

    @media (max-width: 767px) {
        width: 100%;
    }
}
.site-logout {
    position: fixed;
    bottom: 0px;
    background: #f9fafb;
    width: 16rem;
    left: 0px;
    text-align: center;
    justify-content: center;
    display: flex;
    border-top: 1px solid #e5e7eb;

    @media (max-width: 767px) {
        width: 100%;
    }
}
.item-static .card-header {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    padding: 24px;
    padding-bottom: 10px;
}
.item-content-body {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}
.item-static .item-content-static {
    border-radius: 16px;
    background: #fff;
}
.item-static .item-content-body {
    padding: 24px;
    padding-top: 0px;
    padding-bottom: 0px;
}
.item-content .card {
    background: #fff;
    border-radius: 16px;
}
.item-content-static li.nav-item {
    border-bottom-width: 1px;
    border-bottom-color: #e5e7eb;
}
.item-content-static li.nav-item:last-child {
    border-bottom-width: 0px;
}
.flatpickr-current-month input.cur-year,
.flatpickr-current-month .flatpickr-monthDropdown-months {
    font-size: 16px;
}
.md\:gap-6 {
    gap: 1.5rem;
}
.max-w-2xl {
    max-width: 42rem;
}
.text-blue-500 {
    color: #1180ae !important;
}
.border-blue-500 {
    border-color: #1180ae !important;
}
.mdi-delete {
    color: #e76c21;
}
html,
body {
    overflow-x: hidden !important;
}
.site-table {
    @media (max-width: 767px) {
        max-width: 100%;
        overflow: auto;
    }
}
.search-center {
    position: absolute !important;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
}

.main-container-inner {
    min-height: calc(100vh - 196px);
}
