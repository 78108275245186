.flex-grid {
    position: relative;
}
.item {
    width: 100%;
    display: block;
    position: absolute;
    margin: 5px;
    z-index: 1;
}
.item.muuri-item-dragging {
    z-index: 3;
}
.item.muuri-item-releasing {
    z-index: 2;
}
.item.muuri-item-hidden {
    z-index: 0;
}
.item-content {
    position: relative;
    width: 100%;
    height: 100%;
}
@media (max-width: 400px) {
    .flex-grid {
        display: block;
    }
}
