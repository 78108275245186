@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

a.button {
    -webkit-appearance: button;
    -moz-appearance: button;
    appearance: button;
    text-decoration: none;
}

.muuri-item {
    min-width: 16rem;
}

[x-cloak] {
    display: none !important;
}

@tailwind base;

@layer base {
    p {
        @apply pb-2;
    }
    ul {
        @apply list-disc ml-4;
    }
    ol {
        @apply list-decimal ml-4;
    }
}

@tailwind components;

@tailwind utilities;
